import { Text, VStack } from 'native-base';
import { Alert } from '../../../models/view';
import { generateShortUUID } from '../../../utils';

export const makeSpringEnrollmentConfirmationAlert = (): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Spring Enrollment',
  message: () => {
    return (
      <VStack space={4}>
        <Text>You are enrolled for Spring Mentorship!</Text>
      </VStack>
    );
  },
  canClose: false,
});
