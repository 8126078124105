import { Ionicons } from '@expo/vector-icons';
import * as Clipboard from 'expo-clipboard';
import { Divider, Text, VStack } from 'native-base';
import { Animated } from 'react-native';
import { Button } from '../../../components/ui';
import Curricula from '../../../constants/Curricula';
import { Campaign } from '../../../models/data';
import { Alert } from '../../../models/view';
import { colors } from '../../../styles';
import { generateShortUUID, openUrl } from '../../../utils';
import { nativeAlert } from '../../../utils/ui';

export const BACKGROUND_CHECK_AND_TRAINING_ALERT_TITLE = 'Background Check & Training Required';

export const makeVolunteerCheckboxesAlert = (
  campaign: Campaign,
  userShouldSeeBackgroundAlert: boolean,
  userShouldSeeTrainingAlert: boolean,
  getSettingValueByName: (name: string) => string,
  handleDismissAlert: () => void,
  fadeAnim: Animated.Value,
): Alert => {
  const animatedDismiss = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(() => {
      handleDismissAlert();
    });
  };

  return {
    id: generateShortUUID(),
    type: 'warning',
    title: BACKGROUND_CHECK_AND_TRAINING_ALERT_TITLE,
    message: () => (
      <Animated.View style={{ opacity: fadeAnim }}>
        {renderVolunteerRegisteredAlertContent()}

        <Divider my={2} />

        <VStack space={4}>
          <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Ionicons
              name={userShouldSeeBackgroundAlert ? 'square-outline' : 'checkbox-outline'}
              color={colors.secondary}
              size={22}
            />
            <Text fontStyle="italic" ml={2}>
              Background Check
            </Text>
          </VStack>

          {userShouldSeeBackgroundAlert ? renderBackgroundAlertContent() : <></>}
        </VStack>
        <Divider my={2} />
        <VStack space={4}>
          <VStack space={4}>
            <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Ionicons
                name={userShouldSeeTrainingAlert ? 'square-outline' : 'checkbox-outline'}
                color={colors.secondary}
                size={22}
              />
              <Text fontStyle="italic" ml={2}>
                Volunteer Training
              </Text>
            </VStack>
            {userShouldSeeTrainingAlert ? renderTrainingAlertContent(campaign, getSettingValueByName) : <></>}
          </VStack>
        </VStack>
        {!userShouldSeeBackgroundAlert && !userShouldSeeTrainingAlert && (
          <VStack>
            <VStack flex={2} my={1}>
              <Text>Great Job! You've completed the required background check and training.</Text>
            </VStack>
            <VStack flex={1}>
              <Button text="Dismiss" color="secondary" onPress={() => animatedDismiss()} />
            </VStack>
          </VStack>
        )}
      </Animated.View>
    ),
    canClose: false,
  };
};

const renderVolunteerRegisteredAlertContent = () => {
  return (
    <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Ionicons name={'checkbox-outline'} color={colors.secondary} size={22} />
      <Text fontStyle="italic" ml={2}>
        Registered
      </Text>
    </VStack>
  );
};

const renderTrainingAlertContent = (campaign: Campaign, getSettingValueByName: (name: string) => string) => {
  // Fall back to generic training url and password if no curriculum is set
  let url = 'https://rockthestreetwallstreet.com/curriculum-training';
  let password = 'Training';

  const curriculum = Curricula.find((c) => c.name === campaign.curriculumSet);
  if (curriculum) {
    url = getSettingValueByName(curriculum.curriculum_url_slug);
    password = getSettingValueByName(curriculum.curriculum_password_slug);
  }
  return (
    <VStack>
      <Text>Volunteer Training needs to be completed.</Text>
      <Button
        text="Tap to view training curriculum"
        color="secondary"
        onPress={async () => {
          await Clipboard.setStringAsync(password);
          await nativeAlert(
            'Volunteer Training',
            `The password has been copied to the clipboard. Press "Okay" to continue.`,
          );
          openUrl(url);
          setTimeout(() => {
            Clipboard.setStringAsync('');
          }, 30000);
        }}
      />
      <Text>
        Password: <Text fontFamily="mono">{password}</Text>
      </Text>
      <Text mt={2}>After completion, this notice will be removed within 3 days.</Text>
    </VStack>
  );
};

const renderBackgroundAlertContent = () => {
  return (
    <VStack>
      <Text>
        You need a background check to complete your registration and participate with RTSWS. In the next 48 hours you
        will receive an email inviting you to complete your background check through Verified First. Please check your
        spam box if you do not receive the email.
      </Text>
      <Text>This notification will disappear when your background check is returned and approved.</Text>
    </VStack>
  );
};
