import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Button } from '../../../components/ui';
import { User } from '../../../models/data';
import { Alert } from '../../../models/view';
import { RootStackParamList } from '../../../navigation';
import { generateShortUUID } from '../../../utils';
import { userIsStudent } from '../utils';

export const makeReenrollmentAlert = (currentUser: User): Alert => ({
  id: generateShortUUID(),
  type: 'warning',
  title: 'Re-enroll',
  message: () => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const yearString = `${currentYear}/${nextYear}`;

    const isStudent = userIsStudent(currentUser);
    return (
      <Button
        text={`Enroll for school year ${yearString}`}
        color="primary"
        onPress={() => navigation.navigate(isStudent ? 'StudentReenrollment' : ('VolunteerReenrollment' as any))}
      />
    );
  },
  canClose: false,
});
