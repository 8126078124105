import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Button } from '../../../components/ui';
import { User } from '../../../models/data';
import { Alert } from '../../../models/view';
import { RootStackParamList } from '../../../navigation';
import { generateShortUUID } from '../../../utils';
import { userIsStudent, userIsVolunteer } from '../utils';

export const makeSpringEnrollmentAlert = (currentUser: User): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Spring Enrollment',
  message: () => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    if (userIsStudent(currentUser)) {
      return (
        <Button
          text="Enroll For Spring Mentorship"
          color="primary"
          onPress={() => navigation.navigate('SpringEnrollment')}
        />
      );
    } else if (userIsVolunteer(currentUser)) {
      return (
        <Button text="Sign Up as a Mentor" color="primary" onPress={() => navigation.navigate('SpringEnrollment')} />
      );
    }
  },
  canClose: false,
});
