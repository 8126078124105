import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Divider, Link, Text, VStack } from 'native-base';
import { Animated } from 'react-native';
import { Button } from '../../../components/ui';
import { Alert } from '../../../models/view';
import { RootStackParamList } from '../../../navigation';
import { colors } from '../../../styles';
import { generateShortUUID } from '../../../utils';
import {
  CHECKLIST_ITEM_ENROLLED_FOR_FALL,
  CHECKLIST_ITEM_ENROLLED_FOR_SPRING,
  CHECKLIST_ITEM_REGISTERED_WITH_CAREER_CENTER,
  ChecklistItem,
} from '../useAlerts';

export const BACKGROUND_CHECK_AND_TRAINING_ALERT_TITLE = 'Background Check & Training Required';

export const makeStudentCheckboxesAlert = (
  handleDismissAlert: () => void,
  fadeAnim: Animated.Value,
  checklistItems: ChecklistItem[],
): Alert => {
  const animatedDismiss = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(() => {
      handleDismissAlert();
    });
  };

  return {
    id: generateShortUUID(),
    type: 'warning',
    title: 'Registration Checklist',
    message: () => {
      const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
      const allItemsComplete = checklistItems.reduce((acc, item) => acc && item.status, true);

      return (
        <Animated.View style={{ opacity: fadeAnim }}>
          {checklistItems.map((item, index) => {
            if (item.type === CHECKLIST_ITEM_ENROLLED_FOR_FALL) {
              return renderStudentRegisteredAlertContent(item, index);
            }
            if (item.type === CHECKLIST_ITEM_ENROLLED_FOR_SPRING) {
              return (
                <VStack key={generateShortUUID()}>
                  <VStack mt={2} space={4}>
                    <VStack>
                      <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Ionicons
                          name={item.status ? 'checkbox-outline' : 'square-outline'}
                          color={colors.secondary}
                          size={22}
                        />

                        <Text ml={2} fontStyle="italic">
                          Enrolled for Spring
                        </Text>
                      </VStack>
                    </VStack>
                    {!item.status && (
                      <VStack ml={8}>
                        <Link w="100%" href="" onPress={() => navigation.navigate('SpringEnrollment')}>
                          Enroll for Spring mentorship!
                        </Link>
                      </VStack>
                    )}
                  </VStack>
                  <Divider my={2} />
                </VStack>
              );
            }
            if (item.type === CHECKLIST_ITEM_REGISTERED_WITH_CAREER_CENTER) {
              return (
                <VStack key={generateShortUUID()}>
                  <VStack space={4}>
                    <VStack>
                      <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Ionicons
                          name={item.status ? 'checkbox-outline' : 'square-outline'}
                          color={colors.secondary}
                          size={22}
                        />

                        <Text ml={2} fontStyle="italic">
                          Registered with Alumnae Career Center
                        </Text>
                      </VStack>
                    </VStack>
                    {!item.status && (
                      <VStack ml={8}>
                        <Link href="http://alum.rockthestreetwallstreet.com/" w="100%" isExternal={true}>
                          Register here!
                        </Link>
                      </VStack>
                    )}
                  </VStack>
                </VStack>
              );
            }
          })}

          {allItemsComplete && (
            <VStack key={generateShortUUID()}>
              <Divider my={2} />
              <VStack mt={2}>
                <VStack flex={1}>
                  <Button text="Dismiss" color="secondary" onPress={() => animatedDismiss()} />
                </VStack>
              </VStack>
            </VStack>
          )}
        </Animated.View>
      );
    },
    canClose: true,
  };
};

const renderStudentRegisteredAlertContent = (checklistItem: ChecklistItem, index: number) => {
  return (
    <VStack key={index}>
      <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Ionicons
          name={checklistItem.status ? 'checkbox-outline' : 'square-outline'}
          color={colors.secondary}
          size={22}
        />
        <Text fontStyle="italic" ml={2}>
          Enrolled for Fall
        </Text>
      </VStack>
      <Divider my={2} />
    </VStack>
  );
};
