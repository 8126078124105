import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import { useEffect, useRef, useState } from 'react';
import { Animated, Platform } from 'react-native';
import { useCheckIn, useSettings } from '..';
import { Alert } from '../../models/view';
import { useAppSelector } from '../../store';
import { makeCheckInAlert } from './ui/checkInAlert';
import { makePostAssessmentAlert } from './ui/postAssessmentAlert';
import { makePreAssessmentAlert } from './ui/preAssessmentAlert';
import { makeProgramSurveyAlert } from './ui/programSurveyAlert';
import { makeReenrollmentAlert } from './ui/reenrollmentAlert';
import { makeSpringEnrollmentAlert } from './ui/springEnrollmentAlert';
import { makeSpringEnrollmentConfirmationAlert } from './ui/springEnrollmentConfirmationAlert';
import { makeStudentCheckboxesAlert } from './ui/studentCheckboxesAlert';
import { makeVolunteerCheckboxesAlert } from './ui/volunteerCheckboxesAlert';
import {
  campaignIsStudents,
  shouldShowBackgroundCheckAlert,
  shouldShowPostAssessmentAlert,
  shouldShowPreAssessmentAlert,
  shouldShowProgramSurveyAlert,
  shouldShowReenrollmentAlert,
  shouldShowSpringEnrollmentAlert,
  shouldShowSpringEnrollmentConfirmationAlert,
  shouldShowTrainingAlert,
  STUDENT_CHECKBOXES_DISMISSAL_KEY,
  todayIsFallEnrollmentPeriod,
  todayIsSpringEnrollmentPeriod,
  TRAINING_BACKGROUND_DISMISSAL_KEY,
  userIsMentorInterested,
  userIsStudent,
  userIsVolunteer,
} from './utils';

export const CHECKLIST_ITEM_ENROLLED_FOR_FALL = 'CHECKLIST_ITEM_ENROLLED_FOR_FALL';
export const CHECKLIST_ITEM_ENROLLED_FOR_SPRING = 'CHECKLIST_ITEM_ENROLLED_FOR_SPRING';
export const CHECKLIST_ITEM_REGISTERED_WITH_CAREER_CENTER = 'CHECKLIST_ITEM_REGISTERED_WITH_CAREER_CENTER';

export type ChecklistItem = {
  type: string;
  status: boolean;
};

export const useAlerts = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const campaign = useAppSelector((state) => state.campaigns.currentCampaign);
  const programEvents = [...useAppSelector((state) => state.programEvents.programEvents)];
  const { canCheckIn, currentActivity, handleCheckIn } = useCheckIn();
  const { registrationSubmitted } = useAppSelector((state) => state.registrations);
  const { getSettingValueByName } = useSettings();
  const [checkInClicked, setCheckInClicked] = useState(false);
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [userHasDismissedVolunteerCheckboxesAlert, setUserHasDismissedVolunteerCheckboxesAlert] = useState(true);
  const [userHasDismissedStudentCheckboxesAlert, setUserHasDismissedStudentCheckboxesAlert] = useState(true);
  const firstEvent = programEvents.shift();
  const lastEvent = programEvents.pop();
  const fadeAnim = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    getStoredStudentCheckboxesDismissalStatus();
    getStoredVolunteerCheckboxesDismissalStatus();
  }, []);

  useEffect(() => {
    const newAlerts: Alert[] = [];

    if (!currentUser) {
      setAlerts(newAlerts);
      return;
    }

    if (userIsVolunteer(currentUser) && campaign && currentUser.contact) {
      const userShouldSeeBackgroundAlert = shouldShowBackgroundCheckAlert(currentUser.contact);
      const userShouldSeeTrainingAlert = shouldShowTrainingAlert(currentUser.contact);

      if (userShouldSeeBackgroundAlert || userShouldSeeTrainingAlert) {
        setUserHasDismissedVolunteerCheckboxesAlert(false);
        handleResetBackgroundAndTrainingAlertDismissalStatus();
      }
      if (!userHasDismissedVolunteerCheckboxesAlert) {
        newAlerts.push(
          makeVolunteerCheckboxesAlert(
            campaign,
            userShouldSeeBackgroundAlert,
            userShouldSeeTrainingAlert,
            getSettingValueByName,
            handleDismissVolunteerCheckboxesAlert,
            fadeAnim,
          ),
        );
      }
    }

    if (userIsStudent(currentUser)) {
      const userIsInStudentCampaign = campaign && campaignIsStudents(campaign);

      /**
       * Assemble items to be displayed in the checklist
       */
      const displayedChecklistItems: ChecklistItem[] = [];

      if (todayIsFallEnrollmentPeriod() && (currentUser.eligibleForReenrollment || userIsInStudentCampaign)) {
        displayedChecklistItems.push({
          type: CHECKLIST_ITEM_ENROLLED_FOR_FALL,
          status: true,
        });
      }

      if (todayIsSpringEnrollmentPeriod() && (currentUser.eligibleForReenrollment || userIsInStudentCampaign)) {
        displayedChecklistItems.push({
          type: CHECKLIST_ITEM_ENROLLED_FOR_SPRING,
          status: true,
        });
      }

      displayedChecklistItems.push({
        type: CHECKLIST_ITEM_REGISTERED_WITH_CAREER_CENTER,
        status: true,
      });

      let allChecklistItemsAreTrue = true;

      /**
       * Qualify each checklist item
       */
      displayedChecklistItems.forEach((item) => {
        if (item.type === CHECKLIST_ITEM_ENROLLED_FOR_FALL) {
          if (!userIsInStudentCampaign) {
            item.status = allChecklistItemsAreTrue = false;
          }
        }
        if (item.type === CHECKLIST_ITEM_ENROLLED_FOR_SPRING) {
          if (!userIsMentorInterested(currentUser)) {
            item.status = allChecklistItemsAreTrue = false;
          }
        }
        if (item.type === CHECKLIST_ITEM_REGISTERED_WITH_CAREER_CENTER) {
          if (!currentUser.graduwayId) {
            item.status = allChecklistItemsAreTrue = false;
          }
        }
      });

      /**
       * If any of the checklist items are false, reset the alert dismissal
       */
      if (!allChecklistItemsAreTrue) {
        setUserHasDismissedStudentCheckboxesAlert(false);
        handleResetStudentCheckboxesDismissalStatus();
      }

      /**
       * If the user has not dismissed the alert, show it
       */
      if (!userHasDismissedStudentCheckboxesAlert) {
        newAlerts.push(
          makeStudentCheckboxesAlert(handleDismissStudentCheckboxesAlert, fadeAnim, displayedChecklistItems),
        );
      }

      // Student check-in
      if (campaign && canCheckIn && currentActivity) {
        newAlerts.push(makeCheckInAlert(currentActivity, handleCheckIn, setCheckInClicked, checkInClicked));
      }

      // Student pre-assessment
      if (campaign && shouldShowPreAssessmentAlert(campaign, firstEvent)) {
        newAlerts.push(makePreAssessmentAlert(campaign.preAssessmentLink));
      }

      // Student post-assessment
      if (campaign && shouldShowPostAssessmentAlert(campaign, lastEvent)) {
        newAlerts.push(makePostAssessmentAlert(campaign.postAssessmentLink));
      }
    }

    // End of campaign survey
    if (campaign && shouldShowProgramSurveyAlert(campaign)) {
      newAlerts.push(makeProgramSurveyAlert(campaign.endOfCourseSurvey));
    }

    // Reenrollment
    if (shouldShowReenrollmentAlert(currentUser, registrationSubmitted)) {
      newAlerts.push(makeReenrollmentAlert(currentUser));
    }

    // Spring enrollment
    if (shouldShowSpringEnrollmentAlert(currentUser, campaign)) {
      newAlerts.push(makeSpringEnrollmentAlert(currentUser));
    }

    // Spring enrollment confirmation
    if (campaign && shouldShowSpringEnrollmentConfirmationAlert(currentUser, campaign)) {
      newAlerts.push(makeSpringEnrollmentConfirmationAlert());
    }

    setAlerts(newAlerts);
  }, [
    checkInClicked,
    currentUser,
    campaign,
    registrationSubmitted,
    userHasDismissedStudentCheckboxesAlert,
    userHasDismissedVolunteerCheckboxesAlert,
  ]);

  const getStoredVolunteerCheckboxesDismissalStatus = async () => {
    if (Platform.OS === 'web') {
      const val = await AsyncStorage.getItem(TRAINING_BACKGROUND_DISMISSAL_KEY);
      setUserHasDismissedVolunteerCheckboxesAlert(Boolean(val));
    } else {
      const val = await SecureStore.getItemAsync(TRAINING_BACKGROUND_DISMISSAL_KEY);
      setUserHasDismissedVolunteerCheckboxesAlert(Boolean(val));
    }
  };

  const getStoredStudentCheckboxesDismissalStatus = async () => {
    if (Platform.OS === 'web') {
      const val = await AsyncStorage.getItem(STUDENT_CHECKBOXES_DISMISSAL_KEY);
      setUserHasDismissedStudentCheckboxesAlert(Boolean(val));
    } else {
      const val = await SecureStore.getItemAsync(STUDENT_CHECKBOXES_DISMISSAL_KEY);
      setUserHasDismissedStudentCheckboxesAlert(Boolean(val));
    }
  };

  const handleDismissStudentCheckboxesAlert = () => {
    if (Platform.OS === 'web') {
      AsyncStorage.setItem(STUDENT_CHECKBOXES_DISMISSAL_KEY, 'true');
    } else {
      SecureStore.setItemAsync(STUDENT_CHECKBOXES_DISMISSAL_KEY, 'true');
    }

    setUserHasDismissedStudentCheckboxesAlert(true);
  };

  const handleDismissVolunteerCheckboxesAlert = () => {
    if (Platform.OS === 'web') {
      AsyncStorage.setItem(TRAINING_BACKGROUND_DISMISSAL_KEY, 'true');
    } else {
      SecureStore.setItemAsync(TRAINING_BACKGROUND_DISMISSAL_KEY, 'true');
    }

    setUserHasDismissedVolunteerCheckboxesAlert(true);
  };

  const handleResetBackgroundAndTrainingAlertDismissalStatus = () => {
    if (Platform.OS === 'web') {
      AsyncStorage.removeItem(TRAINING_BACKGROUND_DISMISSAL_KEY);
    } else {
      SecureStore.deleteItemAsync(TRAINING_BACKGROUND_DISMISSAL_KEY);
    }
  };

  const handleResetStudentCheckboxesDismissalStatus = () => {
    if (Platform.OS === 'web') {
      AsyncStorage.removeItem(STUDENT_CHECKBOXES_DISMISSAL_KEY);
    } else {
      SecureStore.deleteItemAsync(STUDENT_CHECKBOXES_DISMISSAL_KEY);
    }
  };

  return { alerts };
};
